<template>
  <SignInLayout :subTitle="this.$t('login.subtitle', { brandName })" :title="this.$t('login.title')">
    <SignIn />
  </SignInLayout>
</template>

<script>
import { mapState } from "pinia";

import SignInLayout from "@/components/SignInLayout";
import SignIn from "@/components/SignInLayout/SignIn";
import { useAppStore } from "@/pinia-store/app";

export default {
  name: "signInView",
  components: {
    SignInLayout,
    SignIn,
  },
  computed: {
    ...mapState(useAppStore, ["brandName"]),
  },
};
</script>
